import classNames from 'classnames';
import React from 'react';
import { batch, connect } from 'react-redux';
import { GRM } from '../../../modules/GameRenderingModule';
import {
    getOriginUrlForIframe,
    hostnameToArenaDomain,
    isSafari,
    loadScript,
    setBodyHeightAuto,
    setBodyHeightOneHundred,
    setBodyOverflowAuto,
    setBodyOverflowHidden,
} from '../../../utils';
import { environment } from '../../config/environment';
import { AchievementEvent } from '../../models/AchievementEvent';
import { AdSettings } from '../../models/AdSettings';
import { ActionTypes } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { GameState, GameUnitState, IframeMessageTypes, KeysEnum, LS_COOKIE_CONSTS } from '../../models/Enums';
import { Game } from '../../models/Game';
import { GameEvents, GameObservable, PurchaseRequestEvent, PurchaseResponseEvent } from '../../models/GameObservable';
import { ARENA_CUSTOM_EVENT, ArenaEvents, GamesWithEagleList, PostMessageDataType } from '../../models/GameRendering';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { AdBlocker } from '../../molecules/AdBlocker/AdBlocker';
import { LeaderboardTabs } from '../../molecules/LeaderboardTab/LeaderboardTab';
import { Preroll, PrerollType } from '../../molecules/Preroll/Preroll';
import { ABTestContext } from '../../services/ABTests/ABTestReact';
import { adBlockDetector } from '../../services/AdBlockerService';
import adsService from '../../services/AdsService';
import { AnalyticsGamePage } from '../../services/Analytics/AnalyticsGamePage';
import { CookieStorageService } from '../../services/CookieStorage';
import DBStorageInstance from '../../services/DBStorageService';
import { DeviceDetector } from '../../services/DeviceDetector';
import { EagleLevelupService } from '../../services/EagleLevelupService';
import { EagleLoginService } from '../../services/EagleLoginService';
import { GamesWithEagleService } from '../../services/GamesWithEagleService';
import GemsService from '../../services/GemsService';
import { TGRMGamesList } from '../../services/GRMGamesService';
import { IframeGamesList } from '../../services/IframeGamesService';
import { LocalStorageService } from '../../services/LocalStorage';
import { AchievementType, ProfileService } from '../../services/ProfileService';
import RemoteScoreService from '../../services/RemoteScoreService';
import { UrlService } from '../../services/UrlService';
import UUPScoreService from '../../services/UUPScoreService';
import { saveScore } from '../../store/ducks/gameScores';
import { setGameState, setGameUnitState } from '../../store/ducks/gameState';
import { setGemsAmount } from '../../store/ducks/gems';
import { setHeaderGemsAmount } from '../../store/ducks/header';
import { setLeaderboardList } from '../../store/ducks/leaderboard';
import { setLoginOpen, setShopOpen, TLoginReducer } from '../../store/ducks/modal';
import { setUserTopScores } from '../../store/ducks/userTopScores';
import { AppState } from '../../store/types';
import { checkPrerollIsOn } from '../../utils/checkPrerollIsOn';
import { MiscUtils } from '../../utils/MiscUtils';
import { GameEnd } from '../GameEnd/GameEnd';
import styles from './Game.css';
import { GameUnit, TProps } from './GameUnit';
import { GRMAds } from './GRMAds';
import EaglePaymentService from '../../services/EaglePaymentService';
import { GamesWithOwnIframe, TypeEnvType, TArenaGame } from '@arkadium/modules';
import { GDPRMediatorService } from '../../services/Analytics/GDPR';

type GRMProps = GRM.TypeGRMProps;

type GameUnitProps = TProps;
const GameRenderingModule = GRM.GameRenderingModule;
const gameStates = [GameState.GAME, GameState.REWARD, GameState.INTERSTITIAL];
const boostPrice = 100;

enum ARK_MESSAGES {
    END_PREROLL = 'arkadium:endPreroll',
}

type GameContainerProps = {
    config: ArenaConfig;
    gameState: GameState;
    game: Game;
    currentLang: string;
    userAuthStatus: string;
    gameScore?: number;
    dispatch?: any;
    user: User | EagleUser;
    aspectRatio: number;
    iframeGamesList: IframeGamesList;
    stopMobileCrawlingRenderUpper: () => void;
    onGameStartTimeChange: (gameStartTime: Date) => void;
    gameStartTime: Date;
    activeTab: LeaderboardTabs;
    isShopOpen: boolean;
    adSettings: AdSettings;
    grmGamesList: TGRMGamesList;
    loginState: TLoginReducer;
    gamesWithEagleList: GamesWithEagleList;
};

type GameContainerState = {
    iframeSourceCode: string | null;
    isIframeLoaded: boolean;
    isIframeGame: boolean;
    dbName: string;
    requestEventsQueue: unknown[];
    isForMobileCrawling: boolean;
    fullScreenMob: boolean;
    fullScreenMobTheySell: boolean;
    fullScreenTab: boolean;
    isGameLoading: boolean;
    eaglePassedInGame: boolean;
    isHeavyAdDetected: boolean;
};

class GameContainerDumb extends React.PureComponent<GameContainerProps, GameContainerState> {
    static contextType = ABTestContext;

    private static trackAdBlockDisabled() {
        if (LocalStorageService.getItem(KeysEnum.wasAdBlocker)) {
            LocalStorageService.removeItem(KeysEnum.wasAdBlocker);
            AnalyticsGamePage.adBlock(ActionTypes.AD_BLOCK_DISABLED);
        }
    }

    gameSubject: GameObservable = new GameObservable();
    gameContainerRef = React.createRef<HTMLDivElement>();
    canvasBoxRef = React.createRef<HTMLIFrameElement>();
    loadGameSdkStarted: boolean = false;
    doGameEndTrigger = true;
    private rewardPayload: any = null;
    private interstitialPayload: any = null;

    constructor(props: GameContainerProps) {
        super(props);
        this.state = {
            iframeSourceCode: null,
            isIframeLoaded: false,
            isIframeGame: this.isIframeGame(),
            dbName: '',
            requestEventsQueue: [],
            isForMobileCrawling: true,
            ...this.calculateFullScreenProps(),
            isGameLoading: true,
            eaglePassedInGame: false,
            isHeavyAdDetected: false,
        };
    }

    isIframeGame = () => {
        const { iframeGamesList, game } = this.props;
        const { slug } = game || {};

        if (MiscUtils.isServer) {
            return false;
        }

        if (['Html', 'External'].includes(game.renderingType)) {
            return true;
        }

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('__iframe') === 'false' || urlParams.get('iframe') === 'false') {
            return false;
        }

        if (urlParams.get('__sdk_demo_mode')) {
            return true;
        }

        // JSON check for arkadiumSlug (part of URL)
        return Boolean(
            (urlParams.get('__iframe') === 'true' ||
                urlParams.get('iframe') === 'true' ||
                urlParams.get('__arkver') === 'iframe' ||
                game.isIframe ||
                iframeGamesList.includes(slug)) &&
                !isSafari &&
                !GamesWithOwnIframe.includes(slug)
        );
    };

    addQPtoURL = (url: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        let qp = urlParams.toString();

        if (Boolean(qp)) {
            qp = `?${qp}`;
        }

        return `${url}${qp}`;
    };

    disableInGameGA = () => {
        if (window.arenaName !== 'arcade.lemonde.fr') {
            return;
        }

        const UA_134705967 = [
            1, 3, 4, 5, 6, 7, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 30, 32, 34, 35, 36,
            37, 38, 39, 41, 42, 43, 45, 46, 47, 48, 49, 58,
        ];

        UA_134705967.forEach((index) => {
            window['ga-disable-UA-134705967-' + index] = true;
        });

        const UA_172008740 = [4, 9, 10, 11, 13, 14, 16, 17, 18, 26, 30, 31, 32, 33, 34, 35];

        UA_172008740.forEach((index) => {
            window['ga-disable-UA-134705967-' + index] = true;
        });

        const UA_125415824 = [2, 3, 4, 5];

        UA_125415824.forEach((index) => {
            window['ga-disable-UA-134705967-' + index] = true;
        });
    };

    loadGameSdk() {
        if (this.loadGameSdkStarted) {
            return;
        }

        if (this.props.game.renderingType === 'Html') {
            return this.setState({ iframeSourceCode: this.props.game.assetOriginUrl + '/index.html' });
        }

        if (this.props.game.renderingType === 'External') {
            return this.setState({ iframeSourceCode: this.props.game.version });
        }

        if (this.state.isIframeGame) {
            const iframeFilename = 'iframe-rendering.html';
            const grmIframeFilename = 'iframe-rendering-grm.html';
            const isGrm = this.grmIsUsed();
            const iframeURL = `${getOriginUrlForIframe(isGrm)}/${isGrm ? grmIframeFilename : iframeFilename}`;
            const iframeSourceCode = this.addQPtoURL(iframeURL);

            return this.setState({ iframeSourceCode });
        }

        const { game } = this.props;
        const startDownload = Date.now();
        const setLoadGameSdkStarted = () => {
            this.loadGameSdkStarted = true;
        };

        this.disableInGameGA();

        // old way rendering without iframe
        if (!this.grmIsUsed()) {
            loadScript(`${game.assetOriginUrl}main.min.js`).then(() => {
                AnalyticsGamePage.gameDownload(Date.now() - startDownload);

                try {
                    const sdk = window.__arenax_v1__[`${game.sdkName}Game`].game;
                    // For future implementation of single no-preroll games (from config)
                    const isGameWithoutPreroll = false;

                    if (!checkPrerollIsOn(this.props.config) /* && checkIsItUclickGame()*/ || isGameWithoutPreroll) {
                        // For consistent  behaviour for all games when Preroll is off (like ACS Arena)
                        this.stopMobileCrawlingRender();
                    }

                    const options = this.prepareOptionsForGame();

                    sdk.register(this.gameSubject, options);

                    sdk.render(0, 0, 'game-canvas');
                    setLoadGameSdkStarted();
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }

    componentDidMount() {
        const { config, game } = this.props;

        adBlockDetector.isBlocked.then((isBlocked) => {
            if (isBlocked || adBlockDetector.isHidden) {
                if (config.theme.ads.isAdBlockerEnabled) {
                    this.props.dispatch(setGameState(GameState.AD_BLOCKER));
                } else {
                    this.props.dispatch(setGameState(GameState.GAME));
                }
            } else {
                GameContainerDumb.trackAdBlockDisabled();
                const defaultState = config.theme.shouldShowPreroll(game) ? GameState.PREROLL : GameState.GAME;

                this.props.dispatch(setGameState(defaultState));
            }
        });

        if (this.isUserAuthorized()) {
            this.getUserTopScores();
        }

        this.onResize();

        window.addEventListener('message', this.onMessageHandler);

        window.addEventListener('resize', this.onResize);

        (window as any).arenaName = hostnameToArenaDomain(window.location.hostname); // this is for Game GA

        if (!this.state.isIframeGame) {
            this.gameSubject.xSubscribe(this.onPlatformSpecificEvent);
        }

        const eaglePassedInGame =
            game.hasEagle ??
            GamesWithEagleService.doWeNeedToPassEagleInGame(
                this.props.gamesWithEagleList,
                UrlService.domain,
                game.slug
            );

        this.setState({ eaglePassedInGame });
    }

    onHeavyAdHandler(event: { data: string }) {
        let data: { type: string };

        if (typeof event.data === 'string') {
            try {
                data = JSON.parse(event.data);
            } catch (e) {
                return;
            }
        }

        if (data?.type === 'intervention') {
            this.setState({ isHeavyAdDetected: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onMessageHandler);
        window.removeEventListener('resize', this.onResize);

        if (!this.grmIsUsed()) {
            if (!this.state.isIframeGame) {
                this.gameSubject.xUnsubscribe();
            }
        }
    }

    onMessageHandler = (e: MessageEvent) => {
        void this.onMessage(e);
        this.onMessageQAOnly(e);
        this.onPlatformSpecificEvent(e);
        this.onHeavyAdHandler(e);
    };

    saveAchievement = (playedGame: AchievementEvent) => {
        const { config, game } = this.props;
        const achievementService = config.isEagle ? EagleLevelupService : ProfileService;

        achievementService.saveAchievement({
            eventName: playedGame.userTodayRank ? AchievementType.RANK_ACHEIEVED : AchievementType.SCORE_ACHEIEVED,
            score: playedGame.score,
            gameName: game.name,
            slug: game.slug,
            userTodayRank: playedGame.userTodayRank,
        });
    };
    saveScore = (playedGame: AchievementEvent) => {
        this.dispatchSaveScore(playedGame);
        const scoreService = this.props.config.isEagle ? UUPScoreService : RemoteScoreService;

        scoreService
            .patchCachedLeaderboardWithUserScore(playedGame.slug, this.props.user, playedGame.score, true)
            .then(() => {
                scoreService.getLeaderboard(playedGame.slug, this.props.activeTab, false).then((results) => {
                    this.props.dispatch(setLeaderboardList(results));
                });
            });
    };

    dispatchSaveScore = (playedGame: AchievementEvent | { score: number; slug: string }) => {
        const dateTime = this.props.gameStartTime.toISOString();
        const timeOffset = new Date().getTimezoneOffset();

        this.props.dispatch(saveScore(playedGame.slug, playedGame.score, dateTime, timeOffset));
    };

    async componentDidUpdate(prevProps: GameContainerProps, prevState: GameContainerState) {
        const { game, gameState, userAuthStatus, config } = this.props;
        const gameStateChanged = prevProps.gameState !== gameState;

        if (prevState.isHeavyAdDetected !== this.state.isHeavyAdDetected) {
            const gameCanvas = document.getElementById('game-canvas') as HTMLImageElement | HTMLVideoElement;
            const grmCanvasBox = document.getElementById('canvas-box') as HTMLImageElement | HTMLVideoElement;
            const isGrm = this.grmIsUsed();
            const isIframe = this.isIframeGame();

            // We should to do that for iframe reload in case if we faced with "heavy ad" error
            if (gameCanvas && !isGrm && isIframe) {
                gameCanvas.src = gameCanvas.src;
            }

            if (isGrm && grmCanvasBox) {
                grmCanvasBox.src = grmCanvasBox.src;
            }
        }

        if (gameStateChanged || gameState === GameState.GAME_END) {
            this.onResize();
        }

        if (gameStateChanged && gameState === GameState.GAME) {
            if (prevProps.gameState === GameState.PREROLL || prevProps.gameState === null) {
                this.loadGame(game, config);
            } else {
                this.resumeGame();
            }
        }

        // Run once for authorized user
        if (prevProps.userAuthStatus !== userAuthStatus && this.isUserAuthorized()) {
            this.getUserTopScores();
            this.saveRecentlyPlayed(game.slug);
            this.saveAchievementAndScore();

            if (prevState.requestEventsQueue.length) {
                const gems = await GemsService.getUserGemsAmount().then((gemsAmount) => gemsAmount);

                if (gems < boostPrice) {
                    this.doOpenMenuPurchase();
                }
            }
        }

        this.handleGemsShopClose(prevProps);
        this.handleLoginModalClose(prevProps);
        this.handleMobileCrawling(prevState);
    }

    saveAchievementAndScore = () => {
        const { config, game, gameScore } = this.props;

        if (config.sso) {
            const playedGame = this.getScoreByPlayedGame();

            if (playedGame?.slug && playedGame.score && game.slug === playedGame.slug) {
                if (this.props.gameState !== GameState.GAME_END) {
                    this.saveAchievement(playedGame);
                }

                this.saveScore(playedGame);
            }
        } else {
            this.dispatchSaveScore({
                slug: game.slug,
                score: gameScore,
            });
        }
    };
    handleGemsShopClose = (prevProps: GameContainerProps) => {
        if (this.props.isShopOpen === false && prevProps.isShopOpen !== this.props.isShopOpen) {
            // old game rendering flow
            if (!this.grmIsUsed()) {
                this.sendResponseEvent(false);
            } else {
                window.dispatchEvent(
                    new CustomEvent<PostMessageDataType>(ARENA_CUSTOM_EVENT, {
                        detail: { type: ArenaEvents.CLOSE_SHOP_POPUP },
                    })
                );
            }
        }
    };

    handleLoginModalClose = (prevProps: GameContainerProps) => {
        if (this.props.loginState.isOpen === false && prevProps.loginState.isOpen !== this.props.loginState.isOpen) {
            if (!this.grmIsUsed()) {
                this.sendResponseEvent(false);
            }

            this.props.dispatch(setLoginOpen(false));
        }
    };

    handleMobileCrawling = (prevState: GameContainerState) => {
        if (prevState.isForMobileCrawling !== this.state.isForMobileCrawling) {
            const { fullScreenMob, fullScreenMobTheySell, fullScreenTab } = this.calculateFullScreenProps();

            this.setState({
                fullScreenMob,
                fullScreenMobTheySell,
                fullScreenTab,
            });

            if (fullScreenMob) {
                this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_MOB));
            }

            if (fullScreenMobTheySell) {
                this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_MOB_THEY_SELL));
            }

            if (fullScreenTab) {
                this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_TAB));
            }
        }
    };

    savePurchaseRequest = async (event: PurchaseRequestEvent) => {
        // old game rendering flow
        if (!this.grmIsUsed()) {
            const purchaseRequestId = event.payload.payload;
            const queue = [...this.state.requestEventsQueue, purchaseRequestId];

            this.setState({ requestEventsQueue: queue });
        }

        if (this.props.userAuthStatus !== UserAuthStatus.USER_AUTHORIZED) {
            this.doOpenMenuLogin();
        } else {
            this.doOpenMenuPurchase();
        }
    };

    sendResponseEvent = (isSuccess: boolean) => {
        // old game rendering flow
        if (!this.grmIsUsed()) {
            const { requestEventsQueue } = this.state;

            requestEventsQueue.forEach((id, index) => {
                const result = index === requestEventsQueue.length - 1 && isSuccess;
                const responseEvent: PurchaseResponseEvent = {
                    type: GRM.EnumIframeMessageTypes.PURCHASE_RESPONSE,
                    payload: {
                        payload: id,
                        result,
                    },
                };

                this.gameSubject.dispatch(responseEvent, this.isIframeGame && this.postMessageToIframe);
            });
            this.setState({ requestEventsQueue: [] });
        }
    };
    saveRecentlyPlayed = (slug: string) => {
        if (this.props.gameState === GameState.GAME || this.props.gameState === GameState.GAME_END) {
            const { isEagle } = this.props.config;
            const service = isEagle ? EagleLevelupService : ProfileService;

            return service.saveRecentlyPlayed(slug);
        }
    };
    stopMobileCrawlingRender = () => {
        if (this.state.isForMobileCrawling) {
            this.setState({ isForMobileCrawling: false });
            this.props.stopMobileCrawlingRenderUpper();
            this.forceUpdate();
        }
    };

    onResize(_?: any, justForCta?: boolean | undefined) {
        const gameContainer = this?.gameContainerRef?.current as HTMLDivElement;
        const canvasBox = document.body.querySelector('[class*="Game_canvasBox"]') as HTMLIFrameElement;
        const isDisplayAdOn = this?.props?.config?.theme?.shouldShowDisplayAd();

        if (!gameContainer) {
            return;
        }

        const { gameState, aspectRatio, game } = this.props;
        const passedGameHeight = game?.height;
        let minHeightNum = passedGameHeight
            ? game.height
            : aspectRatio
            ? Math.ceil(gameContainer.offsetWidth / this.props.aspectRatio)
            : 0;

        // make game area smaller if arena has no display ads and only for PC
        if (!isDisplayAdOn && DeviceDetector.isDesktop() && game.isFlex) {
            minHeightNum = minHeightNum - 130;
        }

        const minHeight = minHeightNum + 'px';

        if (!justForCta) {
            gameContainer.setAttribute('style', '');
            canvasBox?.setAttribute('style', '');

            if (gameState === GameState.GAME) {
                gameContainer.style.minHeight = '';
                gameContainer.style.height = minHeight;

                if (canvasBox && this.state.isIframeLoaded && DeviceDetector.isDesktop()) {
                    canvasBox.style.minHeight = '';
                    canvasBox.style.height = minHeight;
                    this.postMessageToIframe({
                        type: IframeMessageTypes.RESIZE_CONTAINER,
                        payload: {
                            height: minHeight,
                            minHeight: '',
                            overflow: 'hidden',
                        },
                    });
                }
            } else {
                gameContainer.style.minHeight = minHeight;
                gameContainer.style.height = 'auto';
            }

            gameContainer.style.overflow = 'hidden';

            if (canvasBox) {
                canvasBox.style.overflow = 'hidden';
            }
        } else {
            const ctaLeft = gameContainer.getElementsByClassName('ctaColLeft');
            const ctaLeftEl: any = ctaLeft.length ? ctaLeft[0] : null;

            if (!ctaLeftEl) {
                return;
            }

            const modifyingEl = ctaLeftEl.parentElement.parentElement;

            ctaLeftEl.setAttribute('style', '');

            if (modifyingEl.id !== 'ark_pre-roll') {
                ctaLeftEl.style.display = 'none';
            } else {
                ctaLeftEl.style.width = `calc(${minHeight} - 2 * 2em)`;
                ctaLeftEl.style.height = `calc(${minHeight} - 2 * 2em)`;
            }
        }
    }

    getScoreByPlayedGame(): AchievementEvent | null {
        const result = LocalStorageService.getItem(KeysEnum.arkStatePlayedGame);

        if (result) {
            LocalStorageService.removeItem(KeysEnum.arkStatePlayedGame);
            return JSON.parse(result);
        }

        return null;
    }

    isUserAuthorized = (): boolean => {
        return this.props.userAuthStatus === UserAuthStatus.USER_AUTHORIZED;
    };

    // This feature used by QA stuff to skip preroll
    onMessageQAOnly = (event: any) => {
        const { dispatch, game } = this.props;
        const { type, payload } = event.data || {};

        try {
            if (event.data && event.data.actionName === ARK_MESSAGES.END_PREROLL) {
                dispatch(setGameState(GameState.GAME));
            }

            if (type === GameEvents.CHANGE_SCORE) {
                if (payload !== undefined) {
                    adsService.refresh();
                    document.body.style.overflow = '';
                    // if a custom leaderboard slug is passed as an argument, then use it
                    const slug = event?.slug ? event.slug : game.slug;
                    const timeOffset = new Date().getTimezoneOffset();
                    const startTime = this.props.gameStartTime || new Date();
                    const dateTime = startTime.toISOString();

                    setBodyOverflowAuto();
                    dispatch(saveScore(slug, payload, dateTime, timeOffset));
                }
            }

            if (type === GameEvents.GAME_END) {
                adsService.refresh();
                this.doGameEndTrigger = false;
                dispatch(setGameState(GameState.GAME_END));
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    onMessage = async (event: MessageEvent) => {
        // communication with iframe
        const originUrl = getOriginUrlForIframe(this.grmIsUsed());

        if (this.state.isIframeGame && originUrl.startsWith(event.origin)) {
            switch (event.data.type) {
                case IframeMessageTypes.CHECK_TO_STOP_MOBILE_CRAWLING:
                    // For future implementation of single no-preroll games (from config)
                    const isGameWithoutPreroll = false;

                    if (!checkPrerollIsOn(this.props.config) /* && checkIsItUclickGame()*/ || isGameWithoutPreroll) {
                        // For consistent  behaviour for all games when Preroll is off (like ACS Arena)
                        this.stopMobileCrawlingRender();
                    }

                    break;
                case IframeMessageTypes.ANALYTICS_GAME_DOWNLOAD:
                    AnalyticsGamePage.gameDownload(event.data.payload);
                    break;

                case IframeMessageTypes.SET_LS_ITEM:
                    const { key, value } = event.data.payload;

                    LocalStorageService.setItem(key, value);
                    break;
                case IframeMessageTypes.REMOVE_LS_ITEM:
                    LocalStorageService.removeItem(event.data.payload.key);
                    break;

                case IframeMessageTypes.UPDATE_INDEXED_DB:
                    await this.updateIndexedDB(event.data.payload);
                    break;
                default:
                    break;
            }
        }

        try {
            const userEvent = JSON.parse(event.data);
            const arkComEventKey = 'actionName';
            const arenaEventKey = 'event';

            if (
                userEvent?.[arkComEventKey] === ARK_MESSAGES.END_PREROLL ||
                userEvent?.[arenaEventKey] === ARK_MESSAGES.END_PREROLL
            ) {
                this.props.dispatch(setGameState(GameState.GAME));
            }
        } catch (_) {
            return _;
        }
    };

    initDB = async () => {
        const slug = this.props.game.slug;
        const prefix = '____ark_game_crossword____';
        const dbName = `${prefix}___${slug}___`;

        this.setState({ dbName });
        DBStorageInstance.postfix = slug;
        await DBStorageInstance.init(prefix);
        return dbName;
    };

    updateIndexedDB = async (iframeIndexedDB: any) => {
        await DBStorageInstance.setItem(this.state.dbName, iframeIndexedDB).then((res) => console.debug(res));
    };

    getUserTopScores() {
        const { isEagle } = this.props.config;
        const ScoreService = isEagle ? UUPScoreService : RemoteScoreService;

        ScoreService.getTopScores(this.props.game.slug).then((topScores) =>
            this.props.dispatch(setUserTopScores(topScores))
        );
    }

    onPrerollEnd = () => {
        this.props.dispatch(setGameState(GameState.GAME));
        this.stopMobileCrawlingRender();
    };

    onRewardEnd = () => {
        if (this.state.isIframeGame) {
            this.postMessageToIframe({
                type: IframeMessageTypes.REWARD_CALLBACK,
                payload: { hasReward: true },
            });
        } else {
            this.rewardPayload?.callback?.({ hasReward: true });
        }

        if (this.rewardPayload) {
            this.rewardPayload = null;
        }

        this.props.dispatch(setGameState(GameState.GAME));
    };

    onInterstitialEnd = () => {
        if (this.state.isIframeGame) {
            this.postMessageToIframe({
                type: IframeMessageTypes.INTERSTITIAL_CALLBACK,
                payload: null,
            });
        } else {
            this.interstitialPayload?.callback?.();
        }

        if (this.interstitialPayload) {
            this.interstitialPayload = null;
        }

        this.props.dispatch(setGameState(GameState.GAME));
    };

    postMessageToIframe = (data: any): void => {
        const iframeRef = this.canvasBoxRef?.current;
        const isGrm = this.grmIsUsed();
        const iframeOrigin = getOriginUrlForIframe(isGrm);
        // this is for container level events (like onRewardEnd and onInterstitialEnd) still using postMessageToIframe
        const iframe: any = !isGrm ? iframeRef : document.body.querySelector(`iframe[src^="${iframeOrigin}"]`);

        iframe?.contentWindow?.postMessage?.({ ...data }, iframeOrigin);
    };

    prepareOptionsForGame = () => {
        const { game, currentLang } = this.props;
        const { supportRewardVideo, supportInterstitialVideo } = this.props.config.theme;
        const aiUserId = CookieStorageService.get(LS_COOKIE_CONSTS.APPINSIGHT_USER_ID)?.split('|')[0];
        const authApi = EagleLoginService.authApi;
        const paymentApi = EaglePaymentService.paymentApiService;
        const virtualItemsApi = GemsService.gemsApiService;
        const userGameDataApi = authApi.getUserGameDataApi({ apiRoot: environment.EAGLE_USER_GAME_DATA_API });
        const eagleLibs = {
            '@arkadium/eagle-user-client': authApi,
            '@arkadium/eagle-virtual-items-api-client': virtualItemsApi,
            '@arkadium/eagle-payments-api-client': paymentApi,
            userGameDataApi,
            nestEnvironment: this.props.config.nestEnvironment,
            arenaEnvironment: environment.ENV,
        };
        const options: any = {
            assetOriginUrl: game.assetOriginUrl,
            configUrl: '',
            locale: currentLang,
            sdkName: game.sdkName,
            eventList: [],
            rewardVideoAvailable: supportRewardVideo,
            interstitialVideoAvailable: supportInterstitialVideo,
            ...(aiUserId ? { aiUserId } : {}),
        };

        if (!this.state.isIframeGame && this.state.eaglePassedInGame) {
            options.enviroment = eagleLibs;
        }

        return options;
    };

    getIndexedDBData = async (dbName: string): Promise<any> => {
        return await DBStorageInstance.getItem(dbName);
    };

    onIframeLoad = async () => {
        this.setState({ isIframeLoaded: true });
        const { game } = this.props;
        const options = this.prepareOptionsForGame();
        const isCrossword = game.slug.includes('crossword');
        let arenaIndexedDB = null;

        // first init and get key then getAllData of it
        if (isCrossword) {
            const dbName = await this.initDB();

            arenaIndexedDB = isCrossword ? (await this.getIndexedDBData(dbName)) ?? {} : {};
        }

        const store = window.STORE.getState();
        const gameData = {
            type: IframeMessageTypes.PASS_GAME_DATA,
            payload: {
                game,
                options,
                arenaName: hostnameToArenaDomain(window.location.hostname),
                ...(arenaIndexedDB ? { arenaIndexedDB } : {}),
                store,
                eaglePassedInGame: this.state.eaglePassedInGame,
                nestEnvironment: store.config.nestEnvironment,
                arenaEnvironment: environment.ENV,
            },
        };

        this.postMessageToIframe({
            type: IframeMessageTypes.UPDATE_LOCAL_STORAGE,
            payload: { ...window.localStorage },
        });

        this.postMessageToIframe({
            type: IframeMessageTypes.UPDATE_COOKIE,
            // @ts-ignore
            payload: { ...CookieStorageService.get() },
        });

        // old game rendering flow
        if (!this.grmIsUsed()) {
            this.postMessageToIframe(gameData);
        }
    };

    loadGame(game: Game, config: ArenaConfig) {
        this.stopMobileCrawlingRender();

        AnalyticsGamePage.gameStart();
        this.props.onGameStartTimeChange(new Date());

        if (this.isUserAuthorized()) {
            this.saveRecentlyPlayed(game?.slug);
        }

        const isPrerollRateOption =
            typeof config?.theme?.ads === 'object' &&
            config?.theme?.ads?.DisableVideo &&
            !!config?.theme?.ads?.DisableVideo?.prerollShowRate;

        if (isPrerollRateOption) {
            const gamePlayCount = +LocalStorageService.getItem(KeysEnum.gamePlayCount);

            LocalStorageService.setItem(KeysEnum.gamePlayCount, gamePlayCount + 1);
        }

        // not to duplicate game canvas by using this GameContainer method while GRM Unit has own same one
        // tslint:disable-next-line:no-unused-expression
        !this.grmIsUsed() && this.loadGameSdk();
    }

    resumeGame() {
        AnalyticsGamePage.gameResume();
    }

    onAdRefresh = () => {
        this.doAdsRefresh();
    };
    onEventChange = () => {
        this.doAdsRefresh();
    };

    grmIsUsed(): boolean {
        if (MiscUtils.isServer) {
            return false;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const grmParam = urlParams.get('__grm');

        if (grmParam === 'true') {
            return true;
        }

        if (grmParam === 'false') {
            return false;
        }

        const { grmGamesList, game } = this.props;

        if (game.isGrm) {
            return true;
        }

        const arenaSlug: string = game?.slug;
        const isGameInGrmList = arenaSlug && grmGamesList?.includes?.(arenaSlug);

        // For test purposes, we need to force it to have GRM active
        if (['External', 'Html'].includes(game.renderingType)) {
            return true;
        }

        if (isGameInGrmList) {
            return true;
        }
    }

    doGetBackToGameTab = () => {
        this.doCloseMenu();
    };

    doSetGameObservable = (gameSubject: any) => {
        this.gameSubject = gameSubject;
    };

    doCloseMenu = () => {
        batch(() => {
            this.props.dispatch(setShopOpen(false));
            this.props.dispatch(setLoginOpen(false));
        });
        GRM.makeGameResume();
    };

    doOpenMenuLogin = () => {
        this.props.dispatch(setLoginOpen(true));
    };

    doOpenMenuPurchase = () => {
        this.props.dispatch(setShopOpen(true));
    };

    doUpdateGemsAmount = () => {
        GemsService.getUserGemsAmount()
            .then(this.updateGemsInStoreAndHeader)
            .catch((e) => {
                console.error(e);
            });
    };

    updateGemsInStoreAndHeader = (gems: number) => {
        const { dispatch } = this.props;

        dispatch(setGemsAmount(gems));
        dispatch(setHeaderGemsAmount(gems?.toString()));
    };

    doAdsRefresh = () => {
        adsService.refresh();
    };

    onGameTestReady = () => {
        noop();
    };
    onGameLoad = () => {
        this.setState({ isGameLoading: false });
    };

    onGameStart = () => {
        // fullscreen ctaScreen problem with uclick autostarting games

        const pathName = window?.location.pathname;

        if (
            !pathName?.match(/\/games\/uclick-/gi) &&
            !pathName?.match(/\/games\/amu-/gi) &&
            !pathName?.match(/\/games\/chess/gi)
        ) {
            this.stopMobileCrawlingRender();
        }
    };

    onGameEnd = () => {
        if (this.doGameEndTrigger) {
            adsService.refresh();
            this.doGameEndTrigger = false;
            this.props.dispatch(setGameState(GameState.GAME_END));
            this.props.dispatch(setGameUnitState(null));
        }
    };

    onScoreChange = (event: any) => {
        this.doAdsRefresh();
        document.body.style.overflow = '';
        // if a custom leaderboard slug is passed as an argument, then use it
        const slug = event.slug ? event.slug : this.props.game.slug;
        const timeOffset = new Date().getTimezoneOffset();
        const startTime = this.props.gameStartTime || new Date();
        const dateTime = startTime.toISOString();

        setBodyOverflowAuto();
        this.props.dispatch(saveScore(slug, event.payload, dateTime, timeOffset));
    };

    onRewardStart = (event: any) => {
        if (this.rewardPayload) {
            return;
        }

        this.rewardPayload = event?.payload;
        this.props.dispatch(setGameState(GameState.REWARD));
    };

    onInterstitialStart = (event: any) => {
        if (this.interstitialPayload) {
            return;
        }

        this.interstitialPayload = event.payload;

        this.props.dispatch(setGameState(GameState.INTERSTITIAL));
    };

    onCheckToStopMobileCrawling = () => {
        // For future implementation of single no-preroll games (from config)
        const isGameWithoutPreroll = false;

        if (!checkPrerollIsOn(this.props.config) /* && checkIsItUclickGame()*/ || isGameWithoutPreroll) {
            // For consistent  behaviour for all games when Preroll is off (like ACS Arena)
            this.stopMobileCrawlingRender();
        }
    };
    onPurchaseRequest = (event: PurchaseRequestEvent) => {
        this.savePurchaseRequest(event).then((r) => r);
    };
    onPurchaseUpdate = () => {
        const { dispatch } = this.props;

        GemsService.getUserGemsAmount().then((gems) => {
            batch(() => {
                dispatch(setGemsAmount(gems));
                dispatch(setHeaderGemsAmount(gems?.toString()));
            });
        });
    };

    onUserEvent = (event: any) => {
        const { event_name } = event.payload || {};
        // to fix #154805 - update balance on arena after boost purchase

        if (event_name && event_name === 'VirtualItemSpend') {
            GemsService.getUserGemsAmount().then((gemsAmount) => {
                this.props.dispatch(setGemsAmount(gemsAmount));
            });
        }
    };

    onPlatformSpecificEvent = (baseEvent: MessageEvent) => {
        if ((baseEvent?.data?.source || '').startsWith('react')) {
            return;
        }

        const event: MessageEvent | GRM.TypeGameEvent =
            this.state.isIframeGame && this.state.iframeSourceCode?.startsWith(baseEvent.origin)
                ? baseEvent.data
                : baseEvent;
        const { type, payload } = (event as GRM.TypeGameEvent) || {};

        if (!type) {
            return;
        }

        try {
            switch (type) {
                // GAME CONTAINER HANDLERS
                case GRM.EnumIframeMessageTypes.CHECK_TO_STOP_MOBILE_CRAWLING:
                    this.onCheckToStopMobileCrawling();
                    break;

                case GRM.EnumIframeMessageTypes.ANALYTICS_GAME_DOWNLOAD:
                    AnalyticsGamePage.gameDownload(payload);
                    break;

                case GRM.EnumIframeMessageTypes.TEST_READY:
                    this.onGameLoad();
                    break;

                // GAME UNIT (ORIGINAL) HANDLERS
                case GRM.EnumIframeMessageTypes.GAME_START: {
                    this.onGameStart();

                    break;
                }

                case GRM.EnumIframeMessageTypes.AD_REFRESH: {
                    this.onAdRefresh();
                    break;
                }

                case GRM.EnumIframeMessageTypes.EVENT_CHANGE:
                    this.onEventChange();
                    break;

                case GRM.EnumIframeMessageTypes.CHANGE_SCORE:
                    this.onScoreChange(event);
                    break;

                case GRM.EnumIframeMessageTypes.GAME_END:
                    this.onGameEnd();
                    break;
                case GRM.EnumIframeMessageTypes.REWARD_START:
                    this.onRewardStart(event);

                    break;
                case GRM.EnumIframeMessageTypes.INTERSTITIAL_START:
                    this.onInterstitialStart(event);

                    break;
                case GRM.EnumIframeMessageTypes.PURCHASE_UPDATE:
                    this.onPurchaseUpdate();
                    break;

                case GRM.EnumIframeMessageTypes.PURCHASE_REQUEST:
                    this.onPurchaseRequest(event as PurchaseRequestEvent);
                    break;

                case GRM.EnumIframeMessageTypes.USER_EVENT:
                    this.onUserEvent(event);
                    break;

                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    };

    onUnitMount = () => {
        noop();
    };

    calculateFullScreenProps = () => {
        const isDisplayAdsEnabled = this.props?.config?.theme?.shouldShowDisplayAd?.() as unknown as boolean;
        const isForMobileCrawling = this.state?.isForMobileCrawling;

        return {
            fullScreenMob:
                DeviceDetector.isMobile() &&
                !isForMobileCrawling &&
                !this.props.config.ad?.theySell?.display &&
                isDisplayAdsEnabled,
            fullScreenMobTheySell:
                DeviceDetector.isMobile() &&
                !isForMobileCrawling &&
                (Boolean(this.props.config.ad?.theySell?.display) || !isDisplayAdsEnabled),
            fullScreenTab: DeviceDetector.isTablet() && !isForMobileCrawling,
        };
    };

    onUnitUpdate = (prevProps: any) => {
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 0);

        if (prevProps.isForMobileCrawling !== this.state.isForMobileCrawling) {
            const { fullScreenMob, fullScreenMobTheySell, fullScreenTab } = this.calculateFullScreenProps();

            this.setState({
                fullScreenMob,
                fullScreenMobTheySell,
                fullScreenTab,
            });

            if (fullScreenMob) {
                this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_MOB));
            }

            if (fullScreenMobTheySell) {
                this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_MOB_THEY_SELL));
            }

            if (fullScreenTab) {
                this.props.dispatch(setGameUnitState(GameUnitState.FULLSCREEN_TAB));
            }
        }
    };

    onUnitUnmount = () => {
        if (DeviceDetector.isNotPc()) {
            setBodyOverflowAuto();
            setBodyHeightOneHundred();
        }
    };

    grmGetGameUnitProps = (): GameUnitProps | GRMProps => {
        const { config, gameState, game, currentLang, aspectRatio, user } = this.props;
        const { isIframeGame, iframeSourceCode } = this.state;
        const arenaDomain = UrlService.domain;
        const isGRM: boolean = this.grmIsUsed();
        let gameUnitPropsGRM: GRMProps;
        let gameUnitPropsCommon: GameUnitProps;

        if (!isGRM) {
            gameUnitPropsCommon = {
                isDisplayAdsEnabled: config?.theme?.shouldShowDisplayAd?.() as unknown as boolean,
                game,
                config,
                currentLang,
                gameState,
                onReward: this.onRewardStart,
                onInterstitial: this.onInterstitialStart,
                aspectRatio,
                gameSubject: this.gameSubject,
                // can be fullscreen on pageload (e.g. registered user or preroll-ads is off)
                isForMobileCrawling: this.state.isForMobileCrawling,
                stopMobileCrawlingRender: this.stopMobileCrawlingRender,
                canvasBoxRef: this.canvasBoxRef,
                onIframeLoad: this.onIframeLoad,
                iframeSourceCode,
                isIframeGame,
                gameStartTime: this.props.gameStartTime,
                savePurchaseRequest: this.savePurchaseRequest,
                isGameLoading: this.state.isGameLoading,
            };
        } else {
            const userGameDataApiService = EagleLoginService.authApi.getUserGameDataApi({
                apiRoot: environment.EAGLE_USER_GAME_DATA_API,
            });

            gameUnitPropsGRM = {
                game: game as unknown as TArenaGame,
                isStateGame: gameStates.includes(gameState),
                isStateGamePlaying: gameState === GameState.GAME,
                arenaDomain,
                isServer: MiscUtils.isServer,
                isIframeGame,
                user: user as unknown as GRM.TypeUserModel,
                // @ts-ignore
                authApiService: EagleLoginService.authApi,
                // @ts-ignore Types have separate declarations of a private property 'server'.ts(2322)
                paymentApiService: EaglePaymentService.paymentApiService,
                // @ts-ignore Types have separate declarations of a private property 'server'.ts(2322)
                virtualItemsApiService: GemsService.gemsApiService,
                // @ts-ignore
                userGameDataApiService,
                currentLang: currentLang as GRM.TypeLocaleType,
                keepAliveStatus: true,
                addEventHandlers: {},
                doGetBackToGameTab: this.doGetBackToGameTab,
                doSetGameObservable: this.doSetGameObservable,
                doCloseMenu: this.doCloseMenu,
                doOpenMenuLogin: this.doOpenMenuLogin,
                doOpenMenuPurchase: this.doOpenMenuPurchase,
                doUpdateGemsAmount: this.doUpdateGemsAmount,
                doAdsRefresh: this.doAdsRefresh,
                onGameTestReady: this.onGameTestReady,
                onGameStart: this.onGameStart,
                onGameEnd: this.onGameEnd,
                onScoreChange: this.onScoreChange,
                onRewardStart: this.onRewardStart,
                onInterstitialStart: this.onInterstitialStart,
                onPlatformSpecificEvent: this.onPlatformSpecificEvent,
                onResize: this.onGRMResize,
                onUnitMount: this.onUnitMount,
                onUnitUpdate: this.onUnitUpdate,
                onUnitUnmount: this.onUnitUnmount,
                arenaEnvironment: environment.ENV === TypeEnvType.DEV ? 'dev' : 'prod',
                ...this.calculateCustomClassesForGRM(),
                eaglePassedInGame: this.state.eaglePassedInGame,
                rewardVideoAvailable: config.theme.supportRewardVideo,
                interstitialVideoAvailable: config.theme.supportInterstitialVideo,
                nestEnvironment: config.nestEnvironment,
                getAnalyticsProvidersState: () => GDPRMediatorService.consentBy3rdPartyProvider(),
            };
        }

        return isGRM ? gameUnitPropsGRM : gameUnitPropsCommon;
    };

    calculateCustomClassesForGRM = () => {
        const hidden =
            this.props.gameState === GameState.PREROLL ||
            this.props.gameState === GameState.REWARD ||
            this.props.gameState === GameState.INTERSTITIAL;

        return {
            customCssClassesUnitContainer: [
                styles.unitContainer,
                ...(hidden ? [styles.hidden] : []),
                ...(DeviceDetector.isNotPc() && this.state.isForMobileCrawling ? [styles.fullScreen] : []),
            ],
            customCssClassesCanvasContainer: [
                styles.canvasContainer,
                ...(this.state.isIframeGame ? [styles.isIframeGame] : []),
                ...(this.state.fullScreenMobTheySell ? [styles.fullScreenMobTheySell] : []),
                ...(this.state.fullScreenMob ? [styles.fullScreenMob] : []),
                ...(this.state.fullScreenTab ? [styles.fullScreenTab] : []),
            ],
            customCssClassesCanvasBoxWrapper: [],
            customCssClassesCanvasBox__common: ['game', styles.canvasBox],
            customCssClassesCanvasBox__iframe: ['game', styles.canvasBox],
        };
    };
    onGRMResize = () => {
        const isForMobileCrawling = this.state.isForMobileCrawling;
        const container = this?.gameContainerRef?.current as HTMLDivElement;
        const canvas = document.body.querySelector('[class*="Game_canvasContainer"]');

        if (!container || !canvas) {
            return;
        }

        const notPc = DeviceDetector.isNotPc();

        if (notPc && !isForMobileCrawling) {
            window.scrollTo(0, 0);
            setBodyOverflowHidden();
            setBodyHeightOneHundred();
        } else {
            setBodyOverflowAuto();
            setBodyHeightAuto();
        }
    };

    grmRenderWrapper = () => {
        const { gameState } = this.props;
        const isGameState: boolean = gameStates.indexOf(gameState) !== -1;

        if (!isGameState) {
            return null;
        }

        const grmIsUsed: boolean = this.grmIsUsed();
        const gameUnitProps: GameUnitProps | GRMProps = this.grmGetGameUnitProps();

        console.log('GRM IS USED: ', grmIsUsed);
        console.log('IS IFRAME GAME: ', gameUnitProps.isIframeGame);
        console.log('IFRAME SOURCE: ', (gameUnitProps as GameUnitProps)?.iframeSourceCode);

        return grmIsUsed ? (
            <>
                <GRMAds gameState={this.props.gameState} game={this.props.game} config={this.props.config} />
                {/* @ts-ignore*/}
                <GameRenderingModule {...(gameUnitProps as TGRMProps)} />
            </>
        ) : (
            <GameUnit {...(gameUnitProps as GameUnitProps)} />
        );
    };

    render() {
        const { config, gameState, game, aspectRatio } = this.props;

        return (
            <Container data-element-description="game" ref={this.gameContainerRef}>
                {gameState === GameState.AD_BLOCKER && (
                    // @ts-ignore
                    <AdBlocker game={game} isForMobileCrawling={this.isForMobileCrawling} />
                )}
                {this.grmRenderWrapper()}
                {gameState === GameState.GAME_END && (
                    // no isForMobileCrawling because crawler will not even pass to game-end step
                    <GameEnd
                        gameStartTime={this.props.gameStartTime}
                        game={game}
                        config={config}
                        aspectRatio={aspectRatio}
                        saveRecentlyPlayed={this.saveRecentlyPlayed}
                    />
                )}
                {(gameState === GameState.PREROLL || gameState === null) && (
                    <Preroll
                        config={config}
                        game={game}
                        onEnd={this.onPrerollEnd}
                        isForMobileCrawling={this.state.isForMobileCrawling || gameState === GameState.GAME}
                        // was fullscreen on pageload + problem with uclick autostarting games
                        stopMobileCrawlingRender={this.stopMobileCrawlingRender}
                        containerOnResize={this.onResize}
                    />
                )}

                {gameState === GameState.REWARD && (
                    <Preroll
                        prerollType={PrerollType.REWARD}
                        config={config}
                        game={game}
                        onEnd={this.onRewardEnd}
                        isForMobileCrawling={false} // crawler will not even pass to reward step
                        stopMobileCrawlingRender={this.stopMobileCrawlingRender}
                    />
                )}
                {gameState === GameState.INTERSTITIAL && (
                    <Preroll
                        prerollType={PrerollType.INTERSTITIAL}
                        config={config}
                        game={game}
                        onEnd={this.onInterstitialEnd}
                        isForMobileCrawling={this.state.isForMobileCrawling} // can be fullscreen on pageload
                        stopMobileCrawlingRender={this.stopMobileCrawlingRender}
                    />
                )}
                <button
                    className="testing__game-end-button"
                    data-game-status="end"
                    onClick={() => {
                        window.postMessage({ type: GameEvents.GAME_END }, UrlService.getCurrentOrigin());
                    }}
                >
                    game end button
                </button>
            </Container>
        );
    }
}

const Container = React.forwardRef<any, any>(({ customClassname, ...props }: any, ref: any) => {
    return <div className={classNames(styles.gameContainer, customClassname)} {...props} ref={ref} />;
});

export const GameContainer = connect((state: AppState) => ({
    gameState: state.gameState,
    config: state.config,
    currentLang: state.currentLang,
    userAuthStatus: state.userAuthStatus,
    gameScore: state.gameScore,
    user: state.user,
    iframeGamesList: state.iframeGamesList,
    activeTab: state.leaderboard.activeTab,
    isShopOpen: state.modal.shop.isOpen,
    adSettings: state.config.ad,
    grmGamesList: state.grmGamesList,
    loginState: state.modal.login,
    gamesWithEagleList: state.gamesWithEagleList,
}))(GameContainerDumb);

function noop() {
    return;
}
