import { GDPRMediatorService } from './Analytics/GDPR';

class LocalStorageProvider {
    private data = {};
    private lsUnavailable = false;

    constructor() {
        try {
            window.localStorage.getItem('');
        } catch (e) {
            this.lsUnavailable = true;
        }
    }

    public setItem(key: string, value: any, skipBlocking = false) {
        GDPRMediatorService.isBlockedByGdprConsent().then((isBlocked) => {
            if (isBlocked && !skipBlocking) {
                console.log('LocalStorage.setItem blocked = ' + key);
                return;
            } else {
                if (this.lsUnavailable) {
                    this.data[key] = String(value);
                } else {
                    window.localStorage.setItem(key, String(value));
                }
            }
        });
    }

    public getItem(key: string): string {
        if (this.lsUnavailable) {
            return this.data[key];
        } else {
            const value = window.localStorage.getItem(key);

            if (!value || value === 'undefined' || value === 'null') {
                return null;
            }

            return value;
        }
    }

    public removeItem(key: string) {
        if (this.lsUnavailable) {
            delete this.data[key];
        } else {
            window.localStorage.removeItem(key);
        }
    }
}

export const LocalStorageService = new LocalStorageProvider();
